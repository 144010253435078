import React, { useState, useEffect } from 'react';
import useStore from '../../store/store';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const ViewProjInfo = ({ onProjectInfoUpdate, onClose, projectInfo, projectName, disabled }) => {
  const [formData, setFormData] = useState({
    projectName: '',
    projectAddress: '',
    totalBuildingSqft: '',
    totalLandSqft: '',
    numStories: '',
    buildingCount: '',
    numUnits: '',
    unionOrNonUnion: '',
    projectType: '',
    projectDescription: '',
  });

  const [displayData, setDisplayData] = useState({
    totalBuildingSqft: '',
    totalLandSqft: '',
  });

  const [errors, setErrors] = useState({
    projectName: '',
    projectAddress: '',
    totalBuildingSqft: '',
    totalLandSqft: '',
    numStories: '',
    buildingCount: '',
    numUnits: '',
    unionOrNonUnion: '',
    projectType: '',
  });

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    // Load data from local storage when component mounts
    const savedProjectInfo = localStorage.getItem('projectInfo');
    if (savedProjectInfo) {
      const parsedProjectInfo = JSON.parse(savedProjectInfo);
      console.log('Loaded project info:', parsedProjectInfo);
      setFormData(prevData => ({
        ...prevData,
        ...parsedProjectInfo,
        unionOrNonUnion: parsedProjectInfo.unionOrNonUnion || '',
        projectType: parsedProjectInfo.projectType || '',
      }));
      updateDisplayData(parsedProjectInfo);
    }
  }, []);

  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      ...projectInfo,
      unionOrNonUnion: projectInfo.unionOrNonUnion || '',
      projectType: projectInfo.projectType || '',
    }));
    updateDisplayData(projectInfo);
  }, [projectInfo]);

  const updateDisplayData = (data) => {
    setDisplayData({
      totalBuildingSqft: formatNumber(data.totalBuildingSqft),
      totalLandSqft: formatNumber(data.totalLandSqft),
    });
  };

  const formatNumber = (value) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  };

  const unformatNumber = (value) => {
    return value ? value.replace(/,/g, '') : '';
  };

  const handleEditClick = () => {
    if (!disabled) {
      setEditMode(true);
    }
  };

  const handleSaveClick = () => {
    if (disabled) return;

    const newErrors = {
      projectName: formData.projectName.trim() === '' ? 'Project Name is required' : '',
      projectAddress: !formData.projectAddress || !formData.projectAddress.match(/^[0-9]+\s[A-Za-z0-9\s,'-]*$/) ? 'Please enter a valid address' : '',
      totalBuildingSqft: !formData.totalBuildingSqft || formData.totalBuildingSqft <= 0 ? 'Total Building Sq. Ft. must be greater than 0' : '',
      totalLandSqft: !formData.totalLandSqft || formData.totalLandSqft <= 0 ? 'Total Land Sq. Ft. must be greater than 0' : '',
      numStories: !formData.numStories || formData.numStories <= 0 ? 'Number of Stories must be greater than 0' : '',
      buildingCount: !formData.buildingCount || formData.buildingCount <= 0 ? 'Number of Buildings must be greater than 0' : '',
      numUnits: !formData.numUnits || formData.numUnits <= 0 ? 'Number of Units must be greater than 0' : '',
      unionOrNonUnion: !formData.unionOrNonUnion ? 'Labor Type is required' : '',
      projectType: !formData.projectType ? 'Project Type is required' : '',
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === '')) {
      setEditMode(false);
      console.log('Saving project info:', formData);
      onProjectInfoUpdate(formData);
    }
  };

  const handleChange = (field, value) => {
    let updatedValue = value === undefined ? '' : value;
    if (field === 'totalBuildingSqft' || field === 'totalLandSqft') {
      updatedValue = unformatNumber(updatedValue);
      setDisplayData(prev => ({
        ...prev,
        [field]: formatNumber(updatedValue)
      }));
    }
    setFormData(prevData => ({
      ...prevData,
      [field]: updatedValue
    }));
  };

  const inputSx = {
    '& label': {
      color: 'rgba(0, 0, 0, 0.6)', // default color
    },
    '&:hover label': {
      color: 'success.main',
    },
    '& label.Mui-focused': {
      color: 'success.main',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'success.main',
      },
    },
    '&.Mui-disabled': {
      '& label': {
        color: 'rgba(0, 0, 0, 0.38)', // grayed out color when disabled
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.26)', // grayed out border when disabled
        },
      },
    },
  };

  return (
    <Box maxWidth="md" mx="auto"       
        sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '5px',
        padding: '30px',
        bgcolor: 'white',
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} >
        <Typography variant="h6" gutterBottom>
          <strong>Enter your project details here</strong>
        </Typography>
        <IconButton onClick={onClose} disabled={disabled}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
        {[
          { label: 'Project Name', field: 'projectName', required: true },
          { label: 'Project Address', field: 'projectAddress', required: true },
          { label: 'Total Building Sq. Ft.', field: 'totalBuildingSqft', endAdornment: 'sq ft', required: true, formatted: true },
          { label: 'Total Land Sq. Ft.', field: 'totalLandSqft', endAdornment: 'sq ft', required: true, formatted: true },
          { label: 'Number of Stories', field: 'numStories', endAdornment: 'stories', required: true },
          { label: 'Number of Buildings', field: 'buildingCount', endAdornment: 'buildings', required: true },
          { label: 'Number of Units', field: 'numUnits', endAdornment: 'units', required: true },
        ].map(({ label, field, endAdornment, required, formatted }) => (
          <Grid item xs={12} sm={6} key={field}>
            <TextField
              label={label}
              value={formatted ? displayData[field] : formData[field]}
              onChange={(e) => handleChange(field, e.target.value)}
              fullWidth
              margin="dense"
              InputProps={{
                readOnly: !editMode,
                endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
              }}
              disabled={!editMode}
              error={errors[field] !== ''}
              helperText={errors[field]}
              color="success"
              required={required}
              sx={inputSx}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            margin="dense"
            variant="outlined"
            error={errors.unionOrNonUnion !== ''}
            disabled={!editMode}
            required
            sx={inputSx}
          >
            <InputLabel>Labor Type</InputLabel>
            <Select
              value={formData.unionOrNonUnion || ''}
              onChange={(e) => handleChange('unionOrNonUnion', e.target.value)}
              label="Labor Type"
              color="success"
            >
              <MenuItem value="Union">Union</MenuItem>
              <MenuItem value="Non-Union">Non-Union</MenuItem>
              <MenuItem value="Mixed">Mixed</MenuItem>
            </Select>
            <FormHelperText>{errors.unionOrNonUnion}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            margin="dense"
            variant="outlined"
            error={errors.projectType !== ''}
            disabled={!editMode}
            required
            sx={inputSx}
          >
            <InputLabel>Project Type</InputLabel>
            <Select
              value={formData.projectType || ''}
              onChange={(e) => handleChange('projectType', e.target.value)}
              label="Project Type"
              color="success"
            >
              <MenuItem value="Assisted Living">Assisted Living</MenuItem>
              <MenuItem value="Cold Storage">Cold Storage</MenuItem>
              <MenuItem value="Hotel">Hotel</MenuItem>
              <MenuItem value="Industrial">Industrial</MenuItem>
              <MenuItem value="Medical">Medical</MenuItem>
              <MenuItem value="Mixed Use">Mixed Use</MenuItem>
              <MenuItem value="Mobile Home">Mobile Home</MenuItem>
              <MenuItem value="Multifamily">Multifamily</MenuItem>
              <MenuItem value="Office">Office</MenuItem>
              <MenuItem value="Residential">Residential</MenuItem>
              <MenuItem value="Retail">Retail</MenuItem>
              <MenuItem value="Yard">Yard</MenuItem>
              </Select>
            <FormHelperText>{errors.projectType}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Project Description"
            value={formData.projectDescription}
            onChange={(e) => handleChange('projectDescription', e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="dense"
            InputProps={{
              readOnly: !editMode,
            }}
            disabled={!editMode}
            color="success"
            sx={inputSx}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center">
      <Button
        variant="contained"
        startIcon={editMode ? <SaveIcon /> : <EditIcon />}
        onClick={editMode ? handleSaveClick : handleEditClick}
        disabled={disabled}
        sx={{
          fontFamily: "SF Pro Display",
          padding: "0.5rem 1rem",
          mt: 2,
          backgroundColor: "#38824D",
          textTransform: "capitalize",
          fontSize: "1rem",
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: "#274d35",
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            backgroundColor: "white",
            color: "white",
          },
        }}
      >
        {editMode ? 'Save' : 'Edit'}
      </Button>
      </Box>
    </Box>
  );
};

export default ViewProjInfo;