import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Button,
  Typography, 
  LinearProgress, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  IconButton, 
  Chip,
  Snackbar,
  Alert,
  Paper,
  CircularProgress
} from '@mui/material';
import { Delete as DeleteIcon, CloudUpload as CloudUploadIcon, Close as CloseIcon } from '@mui/icons-material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { styled } from '@mui/material/styles';
import useStore from '../../store/store';

const Input = styled('input')({
  display: 'none',
});

const BidBookUploader = ({ onClose, rowId }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trade, setTrade] = useState('');
  const fileInputRef = useRef(null);
  const { updateRow, setExpandedRows, rows } = useStore();

  useEffect(() => {
    // Find the row with the matching rowId
    const row = rows.find(row => row.id === rowId);
    if (row) {
      setTrade(row.trade || 'Unknown Trade');
    }
  }, [rowId, rows]);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFiles = (files) => {
    const newFiles = Array.from(files);
    setUploadedFiles(prevFiles => {
      const uniqueNewFiles = newFiles.filter(newFile => 
        !prevFiles.some(existingFile => existingFile.name === newFile.name)
      );
      return [...prevFiles, ...uniqueNewFiles];
    });
    showSnackbar('New file(s) added successfully', 'success');
  };

  const onSelect = (event) => {
    handleFiles(event.target.files);
  };

  const onRemove = (fileName) => {
    setUploadedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    showSnackbar('File removed from list', 'info');
  };

  const onClear = () => {
    setUploadedFiles([]);
    showSnackbar('All files have been cleared', 'info');
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    handleFiles(files);
  };


  const handleQualifyNow = async () => {
    if (uploadedFiles.length === 0) {
      showSnackbar('Please select files before processing', 'warning');
      return;
    }
  
    setIsLoading(true);
    showSnackbar('Files are being processed...', 'info');
    
    const formData = new FormData();
    uploadedFiles.forEach(file => {
      formData.append('file', file);
    });
    
    const userId = localStorage.getItem('user_id');
    formData.append('user_id', userId);
    formData.append('row_id', rowId);
  
    const apiUrl = process.env.NODE_ENV === 'development'
      ? 'http://localhost:10000/api/process_bids'
      : 'https://backend-2m8c.onrender.com/api/process_bids';
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Server response:', result); // Log the entire response for debugging

      if (result && typeof result === 'object') {
        let nestedData;
        if (Array.isArray(result)) {
          // If the result is an array, use it directly as nestedData
          nestedData = result;
        } else if (result.nestedData) {
          // If the result has a nestedData property, use that
          nestedData = result.nestedData;
        } else {
          // If the result is an object but doesn't have nestedData, use the entire object
          nestedData = result;
        }

        updateRow(rowId, { nestedData });
        setExpandedRows(prevExpandedRows => ({...prevExpandedRows, [rowId]: true}));
        showSnackbar('Files processed successfully', 'success');
        onClose();
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error processing files:', error);
      showSnackbar(`Failed to process files: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: 'auto', margin: 'auto', position: 'relative' }}>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" gutterBottom sx={{ mb: .55, fontWeight: 'bold', fontFamily: 'SF Pro Display' }}>
        {`${rowId} - ${trade}`}
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontFamily: 'SF Pro Display' }}>
        Upload bids to your BidBook
      </Typography>
      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <input
          ref={fileInputRef}
          accept=".doc,.docx,.pdf"
          id="contained-button-file"
          multiple
          type="file"
          onChange={onSelect}
          style={{ display: 'none' }}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            sx={{
              backgroundColor: "#38824D",
              '&:hover': {
                backgroundColor: "#274d35",
              },
              textTransform: "capitalize",
            }}
          >
            Choose Files
          </Button>
        </label>
        {uploadedFiles.length > 0 && (
          <Button 
            onClick={onClear} 
            sx={{ color: "#38824D", textTransform: "capitalize" }}
          >
            Clear All
          </Button>
        )}
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={uploadedFiles.length > 0 ? 100 : 0} 
        sx={{ 
          mb: 2, 
          backgroundColor: '#e0e0e0',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#38824D',
          },
        }} 
      />
      <Paper 
        elevation={0} 
        sx={{ 
          border: '2px dashed #38824D', 
          borderRadius: 2, 
          p: 2, 
          mb: 2, 
          backgroundColor: isDragging ? '#38824D15' : (uploadedFiles.length === 0 ? '#f0f8f0' : 'transparent'),
          transition: 'background-color 0.3s'
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {uploadedFiles.length === 0 ? (
          <Typography align="center" color="textSecondary">
            Click Choose Files or drag and drop your bids here to upload.
          </Typography>
        ) : (
          <List sx={{ maxHeight: '400px', overflowY: 'auto', p: 0 }}>
            {uploadedFiles.map((file, index) => (
              <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                <ListItemText
                  primary={file.name}
                  secondary={new Date().toLocaleDateString()}
                  primaryTypographyProps={{ noWrap: true, fontSize: '0.9rem' }}
                  secondaryTypographyProps={{ fontSize: '0.8rem' }}
                />
                <ListItemSecondaryAction>
                  <Chip 
                    label={`${(file.size / 1024 / 1024).toFixed(2)} MB`} 
                    sx={{ 
                      mr: 1,
                      backgroundColor: '#38824D',
                      color: 'white',
                      height: '24px',
                      fontSize: '0.75rem',
                    }} 
                    size="small"
                  />
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(file.name)} size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
      <Button
        onClick={handleQualifyNow}
        disabled={uploadedFiles.length === 0 || isLoading}
        startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <EngineeringIcon />}
        sx={{
          backgroundColor: "#38824D",
          color: 'white',
          padding: '6px 16px',
          '&:hover': {
            backgroundColor: "#274d35",
          },
          '&:disabled': {
            backgroundColor: '#38824D90',
            color: 'white',
          },
          textTransform: "none",
        }}
      >
        {isLoading ? 'Leveling your bids...' : 'Level Bids'}
      </Button>
      {isLoading && (
        <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', left: 'calc(50% + 125px)' }}>
          This can take up to a minute. Feel free to close out this window and keep working.
        </Typography>
      )}
    </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BidBookUploader;