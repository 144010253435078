import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserButton, SignInButton, useUser } from "@clerk/clerk-react";

const theme = createTheme({
  palette: {
    primary: {
      main: '#38824D',
    },
  },
});

export default function NotFound() {
    const { isSignedIn } = useUser();
    const buttonStyle = "bg-gray-700 text-white hover:bg-teal-900 px-4 py-2 rounded-md font-bold text-sm transition duration-300 ease-in-out";

    return (
        <ThemeProvider theme={theme}>
            <div className="relative min-h-screen">
                <div className="absolute top-4 right-4">
                    {isSignedIn ? (
                        <UserButton 
                            mode="modal"
                            appearance={{
                                elements: {
                                    avatarBox: "w-10 h-10"
                                }
                            }}
                        />
                    ) : (
                        <SignInButton mode="modal">
                            <button className={buttonStyle}>
                                LOG IN
                            </button>
                        </SignInButton>
                    )}
                </div>
                <div className="flex items-center justify-center h-screen">
                    <div className="text-center">
                        <CircularProgress size={60} thickness={4} color="primary" />
                        <p className="mt-4 text-gray-600"></p>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}