import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ClerkProvider } from '@clerk/clerk-react';
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import CostIndex from "./pages/CostIndex";
import NotFound from "./pages/NotFound";
import BudgetBuilderPage from "./pages/BudgetBuilderPage";
import SavingsCalc from "./components/SavingsCalc";
import UserProfile from "./components/UserProfile";
import Privacy from "./pages/Privacy";

if (window.location.pathname === "/") {
  window.location.replace("https://home.consight.co");
}

const clerkPubKey = process.env.REACT_APP_FLASK_ENV === 'development'
  ? process.env.REACT_APP_CLERK_PUBLISHABLE_KEY_DEV
  : process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error("Missing Clerk Publishable Key");
}

const redirectUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000/budgetbuilder'
  : 'https://consight.co/budgetbuilder';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={clerkPubKey}
      redirectUrl={redirectUrl}
      afterSignInUrl={redirectUrl}
      afterSignUpUrl={redirectUrl}
      afterSignOutUrl="https://home.consight.co"
    >
      <Router>
        <Routes>
          <Route path="/*" element={<App />}>
            <Route path="budgetbuilder" element={<BudgetBuilderPage />} />
            <Route path="calculator" element={<SavingsCalc />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </ClerkProvider>
  </React.StrictMode>
);

reportWebVitals();