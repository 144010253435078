import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="min-h-screen bg-#e8f1f2 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold mb-6 text-gray-900">Privacy Policy</h1>
          <div className="space-y-6 text-gray-700" style={{ fontFamily: 'SF Pro Display, sans-serif' }}>
            <p>
              Consight values the privacy of all individuals who visit our website. We adhere to a strict policy of not collecting any personally identifiable information from visitors who simply browse our site or download information. Only non-personal information is gathered to enhance our products and services.
            </p>
            <p>
              Any information provided by visitors, for any purpose, may be stored and used by Consight for sale within our products, including, but not limited to, property locations, sizes, and costs. Uniquely identifiable information, such as names, phone numbers, email addresses, etc., will not be collected unless authorized.
            </p>
            <p>
              We also collect your IP address, browser type, visit time, accessed web pages, and the IPs of linked sites during your visit. This data is used for statistical analysis and site improvement purposes only, ensuring an enhanced user experience. It is not linked to any personally identifiable information.
            </p>
            <p>
              In specific instances, such as creating an account, participating in a survey, or sending an email, we may collect your name, email address, and/or the contents of the email, along with any information voluntarily provided in response to the survey. This collection is solely for the purpose of addressing your queries or enabling you to use or improving our services.
            </p>
            <p>
              For visitors requesting specific services through our website, we may require additional information. This information is essential for service provision and is collected in compliance with legal requirements. It is no more detailed than what would be obtained through other means, such as in-person or telephonic interactions.
            </p>
            <p>
              We occasionally seek visitor feedback to enhance our website and service delivery systems. This feedback is collected on an "opt-in" basis, ensuring that visitors willingly provide this data. Subscribers to our services have the freedom to "opt out" at any time.
            </p>
            <p>
              Our commitment is to safeguard your privacy while providing a valuable and efficient service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;