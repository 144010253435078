import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { getSelectedItemsFromLocal, saveSelectedItemsToLocal } from '../components/AddaLineItem/helpers';

const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const useStore = create(
  devtools((set, get) => ({
    // test counter
    count: 0,
    increment: () => set((state) => ({ count: state.count + 1 })),
    decrement: () => set((state) => ({ count: state.count - 1 })),

    // DragDropProposals component
    isExpanded: true,
    setIsExpanded: () => set((state) => ({ isExpanded: !state.isExpanded })),

    expandedRows: {},
    setExpandedRows: (newExpandedRows) => set((state) => ({
      expandedRows: typeof newExpandedRows === 'function' 
        ? newExpandedRows(state.expandedRows) 
        : newExpandedRows
    })),

    // Budget builder data
    contentbb: [],
    setcontentbb: (data) => set({ contentbb: data }),

    // StartNewProject state
    showStartNewProject: false,
    setShowStartNewProjectTrue: () => set({ showStartNewProject: true }),
    setShowStartNewProjectFalse: () => set({ showStartNewProject: false }),

    // Project information
    projectName: "",
    setProjectName: (name) => set({ projectName: name }),
    projectAddress: "",
    setProjectAddress: (address) => set({ projectAddress: address }),
    totalBuildingSqft: "",
    setTotalBuildingSqft: (sqft) => set({ totalBuildingSqft: sqft }),
    totalLandSqft: "",
    setTotalLandSqft: (sqft) => set({ totalLandSqft: sqft }),
    numStories: "",
    setNumStories: (stories) => set({ numStories: stories }),
    buildingCount: "",
    setBuildingCount: (count) => set({ buildingCount: count }),
    unionOrNonUnion: "",
    setUnionOrNonUnion: (unionStatus) => set({ unionOrNonUnion: unionStatus }),
    numUnits: "",
    setNumUnits: (units) => set({ numUnits: units }),
    
    updateProjectInfo: (updatedInfo) => {
      set((state) => ({
        projectName: updatedInfo.projectName || state.projectName,
        projectAddress: updatedInfo.projectAddress || state.projectAddress,
        totalBuildingSqft: updatedInfo.totalBuildingSqft || state.totalBuildingSqft,
        totalLandSqft: updatedInfo.totalLandSqft || state.totalLandSqft,
        numStories: updatedInfo.numStories || state.numStories,
        buildingCount: updatedInfo.buildingCount || state.buildingCount,
        numUnits: updatedInfo.numUnits || state.numUnits,
        unionOrNonUnion: updatedInfo.unionOrNonUnion || state.unionOrNonUnion,
        projectType: updatedInfo.projectType || state.projectType,
        projectDescription: updatedInfo.projectDescription || state.projectDescription,
      }));
    },

    // States for toggleelement in DataGrid
    ctdtState: "",
    setCtdtState: (ctdtState) => set({ ctdtState: ctdtState }),

    searchTerm: "",
    setSearchTerm: (term) => set({ searchTerm: term }),
    nestedSearchTerm: "",
    setNestedSearchTerm: (term) => set({ nestedSearchTerm: term }),

    lineItems: [],
    rows: [],
    setRows: (newRows) => set({ rows: newRows }),

    selectedLineItems: [],
    setSelectedLineItems: (items) => {
      const uniqueItems = Array.from(new Set(items.map(JSON.stringify))).map(JSON.parse);
      set({ selectedLineItems: uniqueItems });
      saveSelectedItemsToLocal(uniqueItems);
    },

    updateRow: (id, updatedFields) => set((state) => ({
      rows: state.rows.map((row) =>
        row.id === id ? { ...row, ...updatedFields } : row
      ),
    })),

    deleteRow: (id) => set((state) => ({
      rows: state.rows.filter((row) => row.id !== id),
      selectedLineItems: state.selectedLineItems.filter((item) => item.id !== id),
    })),

    addRow: (newRow) => set((state) => {
      if (newRow && newRow.id && newRow.trade) {
        // Ensure the id is a string for consistency
        const newRowWithStringId = { ...newRow, id: newRow.id.toString() };
        
        // Create a new array with the existing rows and the new row
        const updatedRows = [...state.rows, newRowWithStringId];
        
        // Sort the array based on the numeric value of the id
        updatedRows.sort((a, b) => {
          const aId = parseInt(a.id, 10);
          const bId = parseInt(b.id, 10);
          return aId - bId;
        });

        // Update selectedLineItems
        const updatedSelectedLineItems = [...state.selectedLineItems, newRowWithStringId];

        console.log("Updated rows:", updatedRows); // For debugging
        console.log("Updated selectedLineItems:", updatedSelectedLineItems); // For debugging

        return { 
          rows: updatedRows,
          selectedLineItems: updatedSelectedLineItems
        };
      }
      console.warn("Attempted to add invalid row:", newRow);
      return state; // Return unchanged state if newRow is invalid
    }),

    setLineItems: (newLineItems) => set({ lineItems: newLineItems }),

    // Filter row by index
    filterRowByIndex: (index) =>
      set((state) => ({
        rows: state.rows.filter((_, i) => i !== index),
      })),

    // Find row by index
    findRowByIndex: (index) =>
      set((state) => ({
        rows: state.rows.filter((_, i) => i === index),
      })),

    // Tap address information for each table
    tapsForAddress: ["123","456","789"],
    addTapForAddress: (newTap) =>
      set((state) => ({ tapsForAddress: [...state.tapsForAddress, newTap] })),
    updateTapForAddress: (tapIndex, updatedAddress) =>
      set((state) => ({
        tapsForAddress: state.tapsForAddress.map((address, index) =>
          index === tapIndex ? updatedAddress : address
        ),
      })),
    deleteTapForAddress: (tapIndex) =>
      set((state) => ({
        tapsForAddress: state.tapsForAddress.filter(
          (_, index) => index !== tapIndex
        ),
      })),

    // Boolean state for add a line item card
    showAddLineItem: false,
    setShowAddLineItemTrue: () => set({ showAddLineItem: true }),
    setShowAddLineItemFalse: () => set({ showAddLineItem: false }),

    // ActionList in BBToolTip state
    showActionList: false,
    setShowActionListTrue: () => set({ showActionList: true }),
    setShowActionListFalse: () => set({ showActionList: false }),

    // x,y position of the ActionList when the user clicks the "More" button
    actionListPosition: { x: 0, y: 0 },
    setActionListPosition: (x, y) => set({ actionListPosition: { x, y } }),

    tempNumFiles: 10,

    // Temp state for the bidbook
    temp_code: "",
    setTempCode: (temp_code) => set({ temp_code: temp_code }),
    temp_trade: "",
    setTempTrade: (temp_trade) => set({ temp_trade: temp_trade }),
    temp_description: "",
    setTempDescription: (temp_description) =>
      set({ temp_description: temp_description }),
    temp_cost: "",
    setTempCost: (temp_cost) => set({ temp_cost: temp_cost }),

    // ClickedRowCodeId
    clickedRowCodeId: "",
    setClickedRowCodeId: (clickedRowCodeId) =>
      set({ clickedRowCodeId: clickedRowCodeId }),

    // Current clicked table
    current_clicked_table: "",
    setCurrent_clicked_table: (current_clicked_table) => set({ current_clicked_table }),

    selectedLineItems: [],
    setSelectedLineItems: (items) => {
      const uniqueItems = Array.from(new Set(items.map(JSON.stringify))).map(JSON.parse);
      set({ selectedLineItems: uniqueItems });
      saveSelectedItemsToLocal(uniqueItems);
    },

    initialDataFetched: false,
    setInitialDataFetched: (value) => set({ initialDataFetched: value }),

    saveSelectedLineItemsToDatabase: async (items) => {
      const { userInfo, initialDataFetched } = get();
      if (!initialDataFetched) {
        console.log('Initial data fetch not completed, skipping save of selected line items');
        return;
      }
      if (userInfo && userInfo.clerk_id) {
        try {
          const response = await fetch(`${apiUrl}/api/users/selected-line-items`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              clerk_id: userInfo.clerk_id,
              selected_line_items: items
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to save selected line items');
          }

          console.log('Selected line items saved to database successfully');
        } catch (error) {
          console.error('Error saving selected line items to database:', error.message);
        }
      } else {
        console.error('User info or Clerk ID is missing');
      }
    },

    fetchSelectedLineItemsFromDatabase: async () => {
      const { userInfo } = get();
      if (userInfo && userInfo.clerk_id) {
        try {
          const response = await fetch(`${apiUrl}/api/users/selected-line-items?clerk_id=${userInfo.clerk_id}`);

          if (!response.ok) {
            throw new Error('Failed to fetch selected line items');
          }

          const data = await response.json();
          const fetchedItems = data.selected_line_items || [];
          
          set({ selectedLineItems: fetchedItems, initialDataFetched: true });
          saveSelectedItemsToLocal(fetchedItems);
          
          console.log('Selected line items fetched successfully');
        } catch (error) {
          console.error('Error fetching selected line items from database:', error.message);
          const localItems = getSelectedItemsFromLocal();
          set({ selectedLineItems: localItems, initialDataFetched: true });
        }
      } else {
        console.error('User info or Clerk ID is missing');
        const localItems = getSelectedItemsFromLocal();
        set({ selectedLineItems: localItems, initialDataFetched: true });
      }
    },

    // User information
    userInfo: null,
    updateUserInfo: (info) => set({ userInfo: info }),

    createUser: async (userData) => {
      console.log("createUser called with:", userData);
      try {
        const url = `${apiUrl}/api/users`;
        console.log("Sending request to:", url);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            auth0_id: userData.sub,
            email: userData.email,
            first_name: userData.given_name,
            last_name: userData.family_name,
          }),
        });
  
        console.log("Response status:", response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("Response data:", data);
        
        if (response.status === 201) {
          console.log('New user created:', data);
        } else {
          console.log('Existing user retrieved:', data);
        }
  
      } catch (error) {
        console.error('Error in user creation/retrieval:', error);
      }
    },
  }))
);

export default useStore;