import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import useStore from '../store/store';
import DataGridDemo from '../components/DataGrid';
import AddaLineItem from '../components/AddaLineItem';
import SearchTradeCostCode from '../components/SearchTradeCostCode';
import ViewProjInfo from '../components/ViewProjInfo';
import ActionList from '../components/BBToolTip';
import ExportTable from '../components/ExportTable';
import Button from '@mui/material/Button';
import PlusIcon from '@mui/icons-material/Add';
import 'primeicons/primeicons.css';
import ProjectSelector from '../components/ProjectSelector'; 
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import BBNavbar from '../components/Navbars/BBNavbar';

const TopBarContainer = ({ children, selectedProject, onProjectChange, projects, onNewProject, onViewProjInfo }) => {
  return (
    <div className="flex items-center justify-between w-full mb-6 mt-2">
      <div className="flex items-center">
        <ProjectSelector
          selectedProject={selectedProject}
          onProjectChange={onProjectChange}
          projects={projects}
          onNewProject={onNewProject}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={onViewProjInfo}
          sx={{
            fontFamily: "SF Pro Display",
            color: "#38824D",
            textTransform: "capitalize",
            borderColor: "#38824D",
            fontWeight: "bold",
            borderRadius: "5px",
            height: "40px",
            width: "60px",
            minWidth: "40px",
            padding: 0,
            marginLeft: "8px",
            "&:hover": {
              borderColor: "#38824D",
              backgroundColor: "#38824D20",
            },
            "& .pi": {
              color: "#38824D",
              fontSize: '1.3rem',
            },
          }}
        >
          <i className="pi pi-file-edit" />
        </Button>
      </div>
      <div className="flex items-center gap-4">
        {children}
      </div>
    </div>
  );
};

export default function BudgetBuilderPage() {
  const { isLoaded, isSignedIn, user } = useUser();
  const navigate = useNavigate();
  const {
    showAddLineItem,
    setShowAddLineItemTrue,
    setShowAddLineItemFalse,
    showActionList,
    actionListPosition,
    tapsForAddress,
    updateTapForAddress,
    rows,
    setRows,
    userInfo,
    selectedLineItems,
    setSelectedLineItems,
    saveSelectedLineItemsToDatabase,
    fetchSelectedLineItemsFromDatabase,
  } = useStore();

  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectData, setProjectData] = useState({});
  const [currentProjectInfo, setCurrentProjectInfo] = useState({});
  const [currentProjectName, setCurrentProjectName] = useState('');
  const [projects, setProjects] = useState([]);
  const [showViewProjInfo, setShowViewProjInfo] = useState(false);
  const [dataGridHeight, setDataGridHeight] = useState('auto');
  const [dataGridKey, setDataGridKey] = useState(0);
  const [psfRecalculationTrigger, setPsfRecalculationTrigger] = useState(0);
  const [exportFileName, setExportFileName] = useState('');
  const [lastSaved, setLastSaved] = useState(null);
  const [lastSavedData, setLastSavedData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const lastSaveTimeRef = useRef(Date.now());
  const changeCountRef = useRef(0);
  const projectDataRef = useRef(null);
  const [dataFetched, setDataFetched] = useState(false);
  const prevSelectedLineItemsRef = useRef(selectedLineItems);
  const isProjectSelected = selectedProject !== null;
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const loadProjectData = useCallback(() => {
    try {
      const storedProjectData = JSON.parse(localStorage.getItem('projectData') || '{}');
      console.log('Loaded project data:', storedProjectData);
      setProjectData(storedProjectData);

      const projectList = Object.keys(storedProjectData).map(key => ({
        label: storedProjectData[key].projectInfo?.projectName || storedProjectData[key].projectName || `New Project ${key}`,
        value: key
      }));
      setProjects(projectList);

      const lastSelectedProject = localStorage.getItem('lastSelectedProject');
      if (lastSelectedProject && storedProjectData[lastSelectedProject]) {
        setSelectedProject(lastSelectedProject);
        setRows(storedProjectData[lastSelectedProject].rows || []);
        setCurrentProjectInfo(storedProjectData[lastSelectedProject].projectInfo || {});
        setCurrentProjectName(storedProjectData[lastSelectedProject].projectInfo?.projectName || storedProjectData[lastSelectedProject].projectName || `New Project ${lastSelectedProject}`);
      } else if (projectList.length > 0) {
        setSelectedProject(projectList[0].value);
        setRows(storedProjectData[projectList[0].value].rows || []);
        setCurrentProjectInfo(storedProjectData[projectList[0].value].projectInfo || {});
        setCurrentProjectName(storedProjectData[projectList[0].value].projectInfo?.projectName || storedProjectData[projectList[0].value].projectName || `New Project ${projectList[0].value}`);
      }
    } catch (error) {
      console.error('Error loading project data:', error);
    }
  }, [setRows]);

  const initializationRef = useRef(false);

  const initializeDefaultProject = useCallback(() => {
    const today = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
    const defaultProjectName = `New Project ${today}`;
    const defaultProjectId = `project_${Date.now()}`;
    
    return {
      [defaultProjectId]: {
        projectName: defaultProjectName,
        rows: [],
        projectInfo: { projectName: defaultProjectName },
      }
    };
  }, []);

  const fetchProjectDataFromDatabase = useCallback(async () => {
    if (isSignedIn && user && !initializationRef.current) {
      initializationRef.current = true;
      try {
        const apiUrl = process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;
  
        const response = await fetch(`${apiUrl}/api/users/project-data?clerk_id=${user.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch project data from database');
        }
  
        const data = await response.json();
        let fetchedProjectData = data.project_data || {};
  
        // If no valid projects exist, initialize with a default project
        if (Object.keys(fetchedProjectData).length === 0 || 
            !Object.values(fetchedProjectData).some(project => 
              project.projectName && project.projectInfo && project.projectInfo.projectName)) {
          fetchedProjectData = initializeDefaultProject();
        } else {
          // Remove any empty projects
          fetchedProjectData = Object.fromEntries(
            Object.entries(fetchedProjectData).filter(([_, project]) => 
              project.projectName && project.projectInfo && project.projectInfo.projectName
            )
          );
        }
  
        setProjectData(fetchedProjectData);
        localStorage.setItem('projectData', JSON.stringify(fetchedProjectData));
  
        const projectList = Object.keys(fetchedProjectData).map(key => ({
          label: fetchedProjectData[key].projectInfo?.projectName || fetchedProjectData[key].projectName,
          value: key
        }));
        setProjects(projectList);
  
        const lastSelectedProject = localStorage.getItem('lastSelectedProject');
        if (lastSelectedProject && fetchedProjectData[lastSelectedProject]) {
          setSelectedProject(lastSelectedProject);
          setRows(fetchedProjectData[lastSelectedProject].rows || []);
          setCurrentProjectInfo(fetchedProjectData[lastSelectedProject].projectInfo || {});
          setCurrentProjectName(fetchedProjectData[lastSelectedProject].projectInfo?.projectName || fetchedProjectData[lastSelectedProject].projectName);
        } else {
          const firstProject = projectList[0];
          setSelectedProject(firstProject.value);
          setRows(fetchedProjectData[firstProject.value].rows || []);
          setCurrentProjectInfo(fetchedProjectData[firstProject.value].projectInfo || {});
          setCurrentProjectName(fetchedProjectData[firstProject.value].projectInfo?.projectName || fetchedProjectData[firstProject.value].projectName);
          localStorage.setItem('lastSelectedProject', firstProject.value);
        }
  
        setDataFetched(true);
        setInitialDataFetched(true);
        console.log('Project data fetched from database and local storage updated');
      } catch (error) {
        console.error('Error fetching project data from database:', error);
        
        // Initialize with a default project
        const defaultProjectData = initializeDefaultProject();
  
        setProjectData(defaultProjectData);
        localStorage.setItem('projectData', JSON.stringify(defaultProjectData));
  
        const projectList = Object.keys(defaultProjectData).map(key => ({
          label: defaultProjectData[key].projectInfo?.projectName || defaultProjectData[key].projectName,
          value: key
        }));
        setProjects(projectList);
  
        setSelectedProject(projectList[0].value);
        setRows(defaultProjectData[projectList[0].value].rows || []);
        setCurrentProjectInfo(defaultProjectData[projectList[0].value].projectInfo || {});
        setCurrentProjectName(defaultProjectData[projectList[0].value].projectInfo?.projectName || defaultProjectData[projectList[0].value].projectName);
  
        setDataFetched(true);
        setInitialDataFetched(true);
        console.log('Initialized default project data due to fetch error');
      } finally {
        setLoading(false);
        initializationRef.current = false;
      }
    } else {
      setLoading(false);
    }
  }, [isSignedIn, user, setRows, initializeDefaultProject]);

  const saveProjectDataToDatabase = useCallback(async (dataToSave, forceSave = false) => {
    if (!initialDataFetched) {
      console.log('Initial data fetch not completed, skipping save');
      return;
    }

    if (isSignedIn && user && Object.keys(dataToSave).length > 0 && 
        !isEqual(dataToSave, lastSavedData) && 
        (forceSave || changeCountRef.current > 0) && 
        !isSaving) {
      setIsSaving(true);
      try {
        const apiUrl = process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;

        const projectResponse = await fetch(`${apiUrl}/api/users/project-data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clerk_id: user.id,
            project_data: dataToSave
          }),
        });

        if (!projectResponse.ok) {
          throw new Error('Failed to save project data to database');
        }

        console.log('Project data saved to database successfully');
        setLastSavedData(dataToSave);
        setLastSaved(new Date());
        changeCountRef.current = 0;
      } catch (error) {
        console.error('Error saving data to database:', error);
      } finally {
        setIsSaving(false);
      }
    }
  }, [isSignedIn, user, lastSavedData, isSaving, initialDataFetched]);

  useEffect(() => {
    if (isLoaded && !initializationRef.current) {
      if (isSignedIn) {
        fetchProjectDataFromDatabase();
      } else {
        navigate('/sign-in');
      }
    }
  }, [isLoaded, isSignedIn, navigate, fetchProjectDataFromDatabase]);

  useEffect(() => {
    if (selectedProject && Object.keys(projectData).length > 0) {
      setProjectData(prevData => ({
        ...prevData,
        [selectedProject]: {
          ...prevData[selectedProject],
          rows: rows,
          projectName: currentProjectName,
          projectInfo: { ...currentProjectInfo, projectName: currentProjectName }
        }
      }));
    }
  }, [rows, selectedProject, currentProjectName, currentProjectInfo]);

  useEffect(() => {
    if (isSignedIn && user) {
      fetchSelectedLineItemsFromDatabase();
    }
  }, [isSignedIn, user, fetchSelectedLineItemsFromDatabase]);

  const handleProjectChange = useCallback((value) => {
    console.log('Changing project to:', value);
    setSelectedProject(value);
    setRows(projectData[value]?.rows || []);
    const projectInfo = projectData[value]?.projectInfo || {};
    setCurrentProjectInfo(projectInfo);
    const projectName = projectInfo.projectName || projectData[value]?.projectName || `New Project ${value}`;
    setCurrentProjectName(projectName);
    setDataGridKey(prevKey => prevKey + 1);
    localStorage.setItem('lastSelectedProject', value);
  }, [projectData, setRows]);

  const handleNewProject = useCallback(() => {
    const newProjectId = `project_${Date.now()}`;
    const today = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
    const newProjectName = `New Project ${today}`;
  
    const updatedProjectData = {
      ...projectData,
      [newProjectId]: {
        projectName: newProjectName,
        rows: [],
        projectInfo: { projectName: newProjectName },
      }
    };
  
    setProjectData(updatedProjectData);
    setProjects(prev => [...prev, { label: newProjectName, value: newProjectId }]);
    setSelectedProject(newProjectId);
    setRows([]);
    setCurrentProjectInfo({ projectName: newProjectName });
    setCurrentProjectName(newProjectName);
    setDataGridKey(prevKey => prevKey + 1);
  
    localStorage.setItem('projectData', JSON.stringify(updatedProjectData));
    localStorage.setItem('lastSelectedProject', newProjectId);
  }, [projectData, setRows]);

  const handleProjectInfoUpdate = (updatedInfo) => {
    setCurrentProjectInfo(updatedInfo);
    setCurrentProjectName(updatedInfo.projectName);
    setProjectData(prevData => ({
      ...prevData,
      [selectedProject]: {
        ...prevData[selectedProject],
        projectInfo: updatedInfo,
        projectName: updatedInfo.projectName
      }
    }));
    localStorage.setItem('projectData', JSON.stringify({
      ...projectData,
      [selectedProject]: {
        ...projectData[selectedProject],
        projectInfo: updatedInfo,
        projectName: updatedInfo.projectName
      }
    }));

    setProjects(prevProjects => 
      prevProjects.map(proj => 
        proj.value === selectedProject ? { ...proj, label: updatedInfo.projectName } : proj
      )
    );
  };

  const debouncedSave = useCallback(
    debounce((data) => saveProjectDataToDatabase(data), 60000),
    [saveProjectDataToDatabase]
  );

  useEffect(() => {
    if (Object.keys(projectData).length > 0) {
      console.log('Saving project data:', projectData);
      localStorage.setItem('projectData', JSON.stringify(projectData));
    }
  }, [projectData]);

  useEffect(() => {
    if (!isEqual(projectData, lastSavedData)) {
      changeCountRef.current += 1;
      projectDataRef.current = projectData;
      saveProjectDataToDatabase(projectData);
    }
  }, [projectData, lastSavedData, saveProjectDataToDatabase]);

  useEffect(() => {
    const forcedSaveInterval = setInterval(() => {
      if (!isEqual(projectDataRef.current, lastSavedData)) {
        saveProjectDataToDatabase(projectDataRef.current, true);
      }
    }, 300000);

    return () => clearInterval(forcedSaveInterval);
  }, [lastSavedData, saveProjectDataToDatabase]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isEqual(projectDataRef.current, lastSavedData)) {
        saveProjectDataToDatabase(projectDataRef.current, true);
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [lastSavedData, saveProjectDataToDatabase]);

  useEffect(() => {
    return () => {
      if (!isEqual(projectData, lastSavedData)) {
        saveProjectDataToDatabase(projectData);
      }
    };
  }, [projectData, lastSavedData, saveProjectDataToDatabase]);

  const handleViewProjInfo = () => {
    setShowViewProjInfo(!showViewProjInfo);
  };

  const handleProjectNameChange = (newProjectName) => {
    setProjectData(prevData => ({
      ...prevData,
      [selectedProject]: {
        ...prevData[selectedProject],
        projectName: newProjectName
      }
    }));
    setProjects(prevProjects => 
      prevProjects.map(proj => 
        proj.value === selectedProject ? { ...proj, label: newProjectName } : proj
      )
    );
    updateExportFileName(newProjectName);
  };

  const handleAddLineItemClose = () => {
    setShowAddLineItemFalse();
    setDataGridKey((prevKey) => prevKey + 1);
  };

  const updateExportFileName = (projectName) => {
    const today = new Date().toISOString().split('T')[0];
    const fileName = `${projectName} - Bid Comparison Sheet - ${today}`;
    setExportFileName(fileName);
    setProjectData(prevData => ({
      ...prevData,
      [selectedProject]: {
        ...prevData[selectedProject],
        exportFileName: fileName
      }
    }));
  };

  const handleViewProjInfoClose = () => {
    setShowViewProjInfo(false);
    setPsfRecalculationTrigger(prev => prev + 1);
  };

  const handleRowUpdate = (updatedRow) => {
    const updatedRows = rows.map(row => 
      row.id === updatedRow.id ? updatedRow : row
    );
    setRows(updatedRows);
  };

  const handleSelectedLineItemsChange = useCallback((newItems) => {
    console.log('Updating selected line items:', newItems);
    setSelectedLineItems(newItems);
  }, [setSelectedLineItems]);

  const handleAddLineItem = useCallback((newItem) => {
    setSelectedLineItems(prevItems => [...prevItems, newItem]);
  }, [setSelectedLineItems]);

  const handleRemoveLineItem = useCallback((itemId) => {
    setSelectedLineItems(prevItems => prevItems.filter(item => item.id !== itemId));
  }, [setSelectedLineItems]);

  if (!isLoaded || loading) return <div>Loading...</div>;
  if (!isSignedIn) return null; // The navigation is handled in the useEffect

  return (
    <div className="flex flex-col min-h-screen">
      <BBNavbar />
      <div
        className="flex flex-col px-4 pt-4 pb-3 w-[calc(100%-10px)] mx-auto text-xs font-semibold bg-white rounded-b-[10px]"
        style={{ height: 'calc(100vh - 110px)' }}
      >
        <div className="flex flex-col h-full">
          <TopBarContainer 
            selectedProject={selectedProject} 
            onProjectChange={handleProjectChange}
            projects={projects}
            onNewProject={handleNewProject}
            onViewProjInfo={handleViewProjInfo}
          >
            <Button
              variant="outlined"
              onClick={setShowAddLineItemTrue}
              sx={{
                backgroundColor: "#38824D",
                borderColor: "#38824D",
                color: "white",
                fontWeight: "bold",
                height: "40px",
                width: "60px",
                "&:hover": {
                  backgroundColor: "#274d35",
                  borderColor: "#274d35",
                },
                "& .MuiButton-startIcon": {
                  color: "white",
                },
              }}
            >
              <PlusIcon />
            </Button>
            <SearchTradeCostCode />
            <ExportTable 
              fileName={currentProjectName}
              rows={rows}
              projectInfo={currentProjectInfo}
            />
          </TopBarContainer>
          <div className="flex-grow overflow-hidden">
            <div style={{ height: '100%', width:'100%', overflowY: 'auto' }}>
              <DataGridDemo 
                key={`${dataGridKey}-${selectedProject}`}
                onRowUpdate={handleRowUpdate} 
                psfRecalculationTrigger={psfRecalculationTrigger}
                rows={projectData[selectedProject]?.rows || []}
                selectedLineItems={selectedLineItems}
                setSelectedLineItems={handleSelectedLineItemsChange}
                onAddLineItem={handleAddLineItem}
                onRemoveLineItem={handleRemoveLineItem}
                projectData={projectData}
                selectedProject={selectedProject}
              />
            </div>
          </div>
          {showAddLineItem && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <AddaLineItem onClose={handleAddLineItemClose} disabled={!isProjectSelected} />
            </div>
          )}
          {showViewProjInfo && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <ViewProjInfo
                onClose={handleViewProjInfoClose}
                onProjectInfoUpdate={handleProjectInfoUpdate}
                projectInfo={currentProjectInfo}
                projectName={currentProjectName}
                disabled={!isProjectSelected}
              />
            </div>
          )}
        </div>
        {showActionList && (
          <div
            className="absolute z-50"
            style={{
              left: actionListPosition["x"]["x"] - 300,
              top: actionListPosition["x"]["y"] - 200,
              position: "absolute",
            }}
          >
            <ActionList />
          </div>
        )}
        {lastSaved && (
          <div className="text-sm text-gray-500 mt-2">
            Last saved: {lastSaved.toLocaleTimeString()}
            {!isEqual(projectData, lastSavedData)}
          </div>
        )}
      </div>
    </div>
  );
}